import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import showdown from 'showdown';

const DisplayForm = forwardRef((props, ref) => {
  const survey = useMemo(() => {
    const s = new Model(props.form_data);
    if (props.theme) s.applyTheme(JSON.parse(props.theme));
    s.showCompleteButton = props.showCompleteButton ?? true;
    // s.onComplete.add((sender, options) => {
    //   props.finalSubmit(JSON.stringify(sender.data), options);
    // });
    return s;
  // eslint-disable-next-line
  }, [props.form_data, props.theme, props.showCompleteButton]);

  if (props.theme) survey.applyTheme(JSON.parse(props.theme));
  // survey.showCompletedPage = false;
  survey.showCompleteButton = props.showCompleteButton ?? true;
  // eslint-disable-next-line no-unused-vars

  useEffect(() => {
    const handleComplete = (sender, options) => {
      props.finalSubmit(JSON.stringify(sender.data), options);
    };

    survey.onComplete.add(handleComplete);

    // Cleanup on component unmount or prop change
    return () => {
      survey.onComplete.remove(handleComplete);
    };
  }, [survey, props.finalSubmit, props]);

  const handleCustomSubmit = () => {
    survey.completeLastPage();
  };

  survey.onTextMarkdown.add((_survey, options) => {
    const converter = new showdown.Converter();
    const html = converter.makeHtml(options.text);
    // eslint-disable-next-line no-param-reassign
    options.html = html;
  });

  // Use imperative handle to expose the method to the parent
  useImperativeHandle(ref, () => ({
    handleCustomSubmit
  }));

  return (
    <div className="no-twp">
      <Survey model={survey} />
    </div>
  );
});

export default DisplayForm;
